import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Auth from "./pages/auth";
import RegisterPage from "./pages/auth/register";
import LoginPage from "./pages/auth/login";
import AppEntryPoint from "./pages/app";
import OnboardingEntrypoint from "./pages/onboarding";
import OnboardingUser from "./pages/onboarding/user";
import OnboardingCompany from "./pages/onboarding/company";
import OnboardingWidget from "./pages/onboarding/widget";
import WidgetTypes from "./pages/guides/widget-types";
import OnboardingPricing from "./pages/onboarding/pricing";
import PaddingLayout from "./layout/padding";
import OnboardingLayout from "./layout/onboarding";
import SubRoutes from "./components/sub-routes";
import { CompleteUserGuard } from "./hoc/withCompleteUser";
import WebAppLayout from "./layout/webapp";
import Dashboard from "./pages/app/dashboard";
import Implementing from "./pages/guides/implementing";
import Settings from "./pages/app/settings";
import Support from "./pages/app/support";
import WidgetList from "./pages/app/widgets";
import NewWidget from "./pages/app/widgets/new";
import SupportById from "./pages/app/support/[id]";
import FaqPage from "./pages/app/support/[id]/[name]";
import OnboardingFinish from "./pages/onboarding/finish";
import Premium from "./pages/app/premium";
import Logout from "./pages/auth/logout";
import Analytics from "./pages/app/analytics";
import Selection from "./pages/app/selection";
import EventsList from "./pages/app/events";
import EventBuilder from "./pages/app/events/[id]";
import { BuildingEventGuard } from "./hoc/withBuildingEvent";
import EventBuilderLayout, {
  EventBuilderStepsEnum,
} from "./layout/event-builder";
import BaseEventBuilder from "./pages/app/events/[id]/base";
import LocationEventBuilder from "./pages/app/events/[id]/location";
import InfosEventBuilder from "./pages/app/events/[id]/infos";
import NewEventFinishPublished from "./pages/app/events/[id]/finish";
import ForgotPassword from "./pages/auth/forgot";
import RecoverPassword from "./pages/auth/recover/[id]";
import CompanyUsersPage from "./pages/app/company/users";
import AppCompanyEntryPoint from "./pages/app/company";
import OnboardingInvite from "./pages/onboarding/invite";
import NotFound from "./pages/404";
import Infos2EventBuilder from "./pages/app/events/[id]/infos-2";
import UpdateWidget from "./pages/app/widgets/[id]";

const App = () => {
  return (
    <Routes>
      {SubRoutes(
        { path: "/app", wrapper: [CompleteUserGuard, WebAppLayout] },
        <Route index element={<AppEntryPoint />} />,
        <Route path="/dashboard" element={<Dashboard />} />,
        <Route path="/selection" element={<Selection />} />,
        <Route path="/analytics" element={<Analytics />} />,
        <Route path="/widgets" element={<WidgetList />} />,
        <Route path="/widgets/new" element={<NewWidget />} />,
        <Route path="/widgets/:id" element={<UpdateWidget />} />,
        <Route path="/support" element={<Support />} />,
        <Route path="/support/:id" element={<SupportById />} />,
        <Route path="/support/:id/:name" element={<FaqPage />} />,
        <Route path="/events" element={<EventsList />} />,
        SubRoutes(
          { path: "/events/:id", wrapper: [BuildingEventGuard] },
          <Route index element={<EventBuilder />} />,
          SubRoutes(
            { wrapper: EventBuilderLayout },
            <Route
              path={EventBuilderStepsEnum.base}
              element={<BaseEventBuilder />}
            />,
            <Route
              path={EventBuilderStepsEnum.location}
              element={<LocationEventBuilder />}
            />,
            <Route
              path={EventBuilderStepsEnum.infos}
              element={<InfosEventBuilder />}
            />,
            <Route
              path={EventBuilderStepsEnum.infos2}
              element={<Infos2EventBuilder />}
            />,
          ),
          <Route path="/finish" element={<NewEventFinishPublished />} />,
        ),
        SubRoutes(
          { path: "/company" },
          <Route index element={<AppCompanyEntryPoint />} />,
          <Route path="/users" element={<CompanyUsersPage />} />,
        ),
        <Route path="/premium" element={<Premium />} />,
        <Route path="/settings" element={<Settings />} />,
      )}
      {SubRoutes(
        { wrapper: PaddingLayout },
        <Route index element={<Home />} />,
        SubRoutes(
          { path: "/auth" },
          <Route index element={<Auth />} />,
          <Route path="/register" element={<RegisterPage />} />,
          <Route path="/login" element={<LoginPage />} />,
          <Route path="/logout" element={<Logout />} />,
          <Route path="/forgot" element={<ForgotPassword />} />,
          <Route path="/recover/:id" element={<RecoverPassword />} />,
        ),
        SubRoutes(
          { path: "/onboarding", wrapper: OnboardingLayout },
          <Route index element={<OnboardingEntrypoint />} />,
          <Route path="/user" element={<OnboardingUser />} />,
          <Route path="/company" element={<OnboardingCompany />} />,
          <Route path="/invite" element={<OnboardingInvite />} />,
          <Route path="/widget" element={<OnboardingWidget />} />,
          <Route path="/pricing" element={<OnboardingPricing />} />,
          <Route path="/finish" element={<OnboardingFinish />} />,
        ),
        SubRoutes(
          { path: "/guides" },
          <Route path="/widget-types" element={<WidgetTypes />} />,
          <Route path="/implementing" element={<Implementing />} />,
        ),
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
