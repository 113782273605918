import CheckButton from "@/components/button/check-button";
import TypeSelectInput from "@/components/inputs/type-select";
import WebsiteInput from "@/components/inputs/website";
import useInput from "@/hooks/useInput";
import Button from "@/components/button";
import {
  MapPinIcon,
  CubeIcon,
  GlobeAltIcon,
  PencilIcon,
  LanguageIcon,
  SwatchIcon,
  Bars3BottomLeftIcon,
} from "@heroicons/react/20/solid";
import { QueryClient, useQuery } from "@tanstack/react-query";
import { Selection, Zone } from "@/types/manager.types";
import { getCompositionsForZone } from "@/service/manager.service";
import Select from "@/components/inputs/select";
import React, { useEffect } from "react";
import TextInput from "@/components/inputs/text";
import useR from "@/hooks/useR";
import { TimenjoyApiAccount } from "@/types/timenjoy.types";
import { cleanName } from "@/utils/name";
import { MutationPayload, useCreation, useUpdate } from "./mutations";
import useValidation from "@/hooks/useValidation";
import { schema } from "./schema";
import useZones from "@/hooks/useZones";
import CheckboxInput from "../inputs/checkbox";
import clsx from "clsx";
import { useFonts } from "@/hooks/useFonts";
import { debounce } from "@/utils/debounce";
import { useToast } from "@chakra-ui/react";
import { SparklesIcon } from "@heroicons/react/24/solid";
import PremiumPaywall from "@/modals/premium-paywall";
import { usePermission } from "@/hooks/usePermissions";
import Spinner from "../spinner";
import CategoryFilter from "../category-filter";
import { distinct } from "@/utils/distinct";
import { captureException } from "@/service/exceptions";
import { isCustomSelection } from "@/utils/isCustomSelection";

type FormProps = {
  next: () => void;
  id?: string;
  defaultWebsite?: string;
  defaultName?: string;
  openPremiumPaywall?: boolean;
};

const maxZones = 3;
const sortFunc = (a: string, b: string) =>
  a.localeCompare(b, "fr", {
    usage: "sort",
    caseFirst: "lower",
    sensitivity: "base",
    ignorePunctuation: true,
  });

function extractZonesAndCompositions(client: QueryClient, id?: string) {
  if (!id)
    return {
      zones: [],
      compositions: [],
    };
  const selection = client.getQueryData<Selection>(["selection", id]);
  if (!selection)
    return {
      zones: [],
      compositions: [],
    };
  const zones = distinct(selection.compositions?.map((c) => c.zone.name) ?? []);
  const compositions = distinct(
    selection.compositions?.map((c) => c.name) ?? [],
  );
  return {
    zones,
    compositions,
  };
}
function getCurrentType(json: string | null | undefined) {
  try {
    if (!json) return "CAROUSEL";
    const data = JSON.parse(json);
    return data.type.toUpperCase() as "CAROUSEL" | "PAGEVIEW" | "MONO";
  } catch (e) {
    return "CAROUSEL";
  }
}

const Options = ({
  next,
  id,
  defaultWebsite = "",
  defaultName = "",
  openPremiumPaywall = false,
}: FormProps) => {
  const { client } = useR();
  const { canUseSelection } = usePermission();
  const apiAccount = client.getQueryData<TimenjoyApiAccount>([
    "apiAccount",
    id,
  ]);
  const currentSelection = client.getQueryData<Selection>(["selection", id]);
  const defaults = extractZonesAndCompositions(client, id);
  const toast = useToast();
  const [isValid, validate] = useValidation(schema);
  const [useSelection, setUseSelection] = React.useState(
    isCustomSelection(currentSelection),
  );
  console.log({ currentSelection });
  const [useCompanyAds, setUseCompanyAds] = React.useState(
    !!currentSelection?.companyConfigId,
  );
  React.useEffect(() => {
    setUseCompanyAds(!!currentSelection?.companyConfigId);
  }, [currentSelection?.companyConfigId]);
  const website = useInput(apiAccount?.white_list?.[0]?.url ?? defaultWebsite);
  const [selectedZones, setSelectedZones] = React.useState<string[]>(
    defaults.zones,
  );
  const [selectedCompositions, setSelectedCompositions] = React.useState<
    string[]
  >(defaults.compositions);
  const name = useInput(cleanName(apiAccount?.name ?? defaultName));
  const [type, setType] = React.useState<"CAROUSEL" | "PAGEVIEW" | "MONO">(
    getCurrentType(currentSelection?.Config?.Customization?.json),
  );
  const font = useInput();
  const color = useInput();
  const lines = useInput("6", {
    validate(v) {
      if (!v) return true;
      const n = parseInt(v);
      if (Number.isNaN(n)) return false;
      return n >= 1 && n <= 6;
    },
    transform(v) {
      if (!v) return v;
      const n = parseInt(v);
      if (Number.isNaN(n)) return v;
      return n;
    },
    onError(v) {
      toast({
        title: `Nombre de lignes invalide (${v})`,
        description: "Le nombre de lignes doit être compris entre 1 et 6",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });
  const fonts = useFonts();
  const previewRef = React.useRef<
    HTMLElement & { kill: Function; populate: Function }
  >(null);

  const render = React.useCallback(
    debounce(() => {
      if (!previewRef.current) return;
      previewRef.current.kill();
      previewRef.current.populate();
    }, 500),
    [previewRef],
  );

  React.useEffect(render, [color, font, lines]);
  const traffic = useInput<HTMLSelectElement>("- de 50K visiteurs");

  const zones = useZones();

  const baseComposition = useQuery({
    queryKey: ["baseComposition", selectedZones, zones],
    queryFn: () => {
      if (!zones) return [];
      const data = selectedZones
        .map((zoneName) => {
          const zone = zones.find((z) => z.name === zoneName);
          if (!zone) return null;
          return zone.id;
        })
        .filter(Boolean) as string[];
      return getCompositionsForZone(...data);
    },
    enabled: !!zones,
    onError(err) {
      console.error(err);
      captureException(err);
    },
  });

  const zoneOptions = distinct(
    zones
      ?.map((zone) => zone.name)
      .filter(Boolean)
      .sort(sortFunc),
  );
  const compositionOptions = distinct(
    baseComposition.data?.map((c) => c.name).sort(sortFunc),
  );

  const computedCompositions = React.useMemo(() => {
    if (!baseComposition.data || !selectedCompositions.length) return [];
    return baseComposition.data
      .filter((c) => selectedCompositions.includes(c.name))
      .map((c) => c.id);
  }, [baseComposition.data, selectedCompositions]);

  useEffect(() => {
    validate(
      {
        zones: selectedZones,
        compositions: useSelection ? undefined : computedCompositions,
        name: name.value,
        type,
        website: website.value,
        traffic: traffic.value,
        useCompanyAds,
      },
      true,
    );
  });

  const creation = useCreation(next);
  const update = useUpdate(next);

  function mutate(e?: React.FormEvent) {
    e?.preventDefault();
    const payload: MutationPayload = {
      name: name.value,
      type,
      website: website.value,
      compositions: useSelection ? undefined : computedCompositions,
      traffic: traffic.value,
      useCompanyAds,
    };
    if (!validate({ ...payload, zones: selectedZones })) return;
    if (!canUseSelection && useSelection) return;
    if (apiAccount) {
      update.mutate(payload);
    } else {
      creation.mutate(payload);
    }
  }

  function updateSelectedZones(zones: string[]) {
    setSelectedZones(zones.slice(0, maxZones));
  }
  function updateSelectedCompositions(compositions: string[]) {
    setSelectedCompositions(compositions.slice(0, maxZones));
  }

  const [isPremiumPopupOpen, setIsPremiumPopupOpen] = React.useState(false);

  return (
    <form
      onSubmit={mutate}
      className="text-subtitle text-subtitle mt-8 grid max-w-3xl grid-cols-2 gap-x-8 gap-y-16 gap-y-8"
    >
      <PremiumPaywall
        open={isPremiumPopupOpen && openPremiumPaywall}
        setOpen={setIsPremiumPopupOpen}
      />
      <div className="col-span-1 w-full space-y-5">
        <label className="flex items-center font-medium">
          <PencilIcon className="text-emphasis mr-1 h-4 w-4" /> Nom
        </label>
        <TextInput
          {...name}
          placeholder="Nom du widget"
          // disabled={!!apiAccount}
        />
      </div>
      <div className="col-span-1 w-screen max-w-full space-y-5">
        <label className="flex items-center font-medium">
          <GlobeAltIcon className="text-emphasis mr-1 h-4 w-4" />
          Site de diffusion
        </label>
        <WebsiteInput {...website} />
      </div>
      <div className="col-span-2"></div>
      <h4 className="col-span-2 text-lg font-semibold tracking-tight text-white">
        Contenu
      </h4>
      <CheckboxInput
        checked={useSelection}
        onClick={() => {
          if (canUseSelection) setUseSelection((a) => !a);
          else setIsPremiumPopupOpen(true);
        }}
      >
        Sélection personnalisée
        {!canUseSelection && (
          <span className="text-konnectz_accent ml-4 flex items-center gap-2 font-semibold tracking-wide">
            {" "}
            <SparklesIcon className="text-konnectz_accent h-5 w-5" /> Premium
          </span>
        )}
      </CheckboxInput>
      <CheckboxInput
        checked={useCompanyAds}
        onClick={() => {
          setUseCompanyAds((a) => !a);
        }}
      >
        Ajouter automatiquement mes annonces
      </CheckboxInput>

      <div className={clsx("col-span-1 space-y-5")}>
        <div>
          <label className="flex items-center font-medium">
            <span className="flex items-center">
              <CubeIcon className="text-emphasis mr-1 h-4 w-4" /> Type de widget
            </span>
          </label>
        </div>
        <div className="grid grid-cols-2 gap-x-3">
          <CheckButton
            content="Carousel"
            checked={type === "CAROUSEL"}
            onClick={() => setType("CAROUSEL")}
          />
          <CheckButton
            content="Agenda"
            // disable={useSelection}
            checked={type === "PAGEVIEW"}
            onClick={() => setType("PAGEVIEW")}
          />
        </div>
      </div>
      <div />
      <div className={clsx("col-span-1 w-full space-y-5")}>
        <label className="flex items-center font-medium">
          <MapPinIcon className="text-emphasis mr-1 h-4 w-4" />
          Choisir un département
        </label>
        {/* <TypeSelectInput
            {...zoneInput}
            disabled={useSelection}
            onSelect={updateCity}
            options={zoneOptions}
            placeholder="Sélectionner un département..."
          /> */}
        <CategoryFilter
          value={selectedZones}
          onChange={updateSelectedZones}
          data={zoneOptions || []}
          className="w-full"
        >
          <div className="bg-background_light hover:border-emphasis relative flex w-full cursor-pointer items-center justify-center gap-2 overflow-hidden text-ellipsis rounded-lg border border-white/20 px-5 py-3 font-medium text-white duration-150 active:shadow-lg">
            {selectedZones.length
              ? `${selectedZones.length} départements sélectionnés (max ${maxZones})`
              : "Sélectionner des départements"}
          </div>
        </CategoryFilter>
      </div>
      <div className={clsx("col-span-1 w-full space-y-5")}>
        <label className="flex items-center font-medium">
          <MapPinIcon className="text-emphasis mr-1 h-4 w-4" /> Sélection
          d'événements
        </label>
        {/* <Select
            disabled={useSelection}
            {...compositionInput}
            options={compositionOptions || []}
          /> */}
        <CategoryFilter
          value={selectedCompositions}
          onChange={updateSelectedCompositions}
          data={compositionOptions || []}
          className="w-full"
          searchable={false}
        >
          <div className="bg-background_light hover:border-emphasis relative flex w-full cursor-pointer items-center justify-center gap-2 overflow-hidden text-ellipsis rounded-lg border border-white/20 px-5 py-3 font-medium text-white duration-150 active:shadow-lg">
            {selectedCompositions.length
              ? `${selectedCompositions.length} catégories sélectionnés (max ${maxZones})`
              : "Sélectionner des catégories"}
          </div>
        </CategoryFilter>
      </div>
      <h4 className="col-span-2 text-lg font-semibold tracking-tight text-white">
        Apparence
      </h4>
      <div className="w-full max-w-md space-y-5">
        <label className="flex items-center font-medium">
          <LanguageIcon className="text-emphasis mr-1 h-4 w-4" />
          Police d'écriture
        </label>
        <TypeSelectInput
          options={fonts.isSuccess ? fonts.data : []}
          {...font}
          placeholder="Police d'écriture"
          emptyMessage="Aucune police d'écriture disponible"
        />
      </div>
      <div className="w-full max-w-md space-y-5">
        <label className="flex items-center font-medium">
          <SwatchIcon className="text-emphasis mr-1 h-4 w-4" />
          Couleur
        </label>
        <TextInput {...color} placeholder="#000000" />
      </div>
      {type === "PAGEVIEW" && (
        <>
          <div className="w-full max-w-md space-y-5">
            <label className="flex items-center font-medium">
              <Bars3BottomLeftIcon className="text-emphasis mr-1 h-4 w-4" />
              Nombre de lignes
            </label>
            <TextInput {...lines} />
          </div>
          <div />
        </>
      )}
      <div className="col-span-2 h-80 max-w-full overflow-hidden">
        <tnj-widget
          data-selection={"base_composition"}
          data-app_secret={"wbcb3frc26gtWAoDE964i"}
          className="-my-7 w-full"
          ref={previewRef}
          data-type="carousel"
          data-watermark="false"
          data-version="4.0.0"
          data-redirect="false"
          data-styles={JSON.stringify({
            card: {
              global: {
                background: "#161b22",
                border: "1px solid #1f2937",
              },
              firstLine: {
                color: color.value ? color.value : undefined,
                fontFamily: font.value ? font.value : undefined,
              },
              secondLine: {
                color: color.value ? color.value : undefined,
                fontFamily: font.value ? font.value : undefined,
              },
              thirdLine: {
                color: color.value ? color.value : undefined,
                fontFamily: font.value ? font.value : undefined,
              },
            },
            arrows: {
              backgroundColor: "#161b22",
            },
          })}
        />
      </div>

      <div className="col-span-2 flex justify-end space-x-4">
        <Button
          key={Date.now()}
          onClick={mutate}
          className="flex items-center gap-2"
          disabled={!isValid}
          clickThroughDisabled
        >
          {apiAccount === undefined ? "Créer mon widget" : "Mettre à jour"}
          {(update.isLoading || creation.isLoading) && (
            <Spinner className="relative top-px -mr-1 h-4 w-4" />
          )}
        </Button>
      </div>
    </form>
  );
};

export default Options;
